import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import { Typography, Button, Grid } from "@mui/material";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <img
            height="90px"
            src={`${REACT_APP_ECLIPSE_API_Local}/uploads/EclipseMy_Web/Company_Logo.png`}
          ></img>
          <div
            style={{
              fontSize: 15,
              color: "#92FE9D",
              marginTop: "-35px",
              marginLeft: "80px",
            }}
          >
            {"ver : " + localStorage.getItem("ver")}
          </div>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <div style={{ textAlign: "right" }}>
            <img
              style={{ borderRadius: 80, width: "80px" }}
              src={""} //------ firebase 2/3
            ></img>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div style={{ textAlign: "center" }}>
            <Typography
              variant="caption"
              display="block"
              style={{ fontSize: "12px", color: "#92FE9D" }}
            >
              {"s"}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              style={{ fontSize: "12px", color: "#92FE9D" }}
            >
              {""}
            </Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              size="small"
              variant="text"
              color="secondary"
              onClick={() => {
                // firebase.auth().signOut(); //------ firebase 2/3
                window.location.reload();
              }}
            >
              Logout
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

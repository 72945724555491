import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { HashRouter, Route, Switch } from "react-router-dom"; // router 1/

import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

// ------------------------------------------------- MUI Table 1/3

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

export default function Page_Home() {
  //  -------------------------- Table column

  const [state, setState] = React.useState({
    columns: [
      {
        title: "",
        field: "gen_dt",
        width: "1%",
        headerStyle: {
          minWidth: "100px",
        },
      },

      {
        title: "",
        field: "gen_dt",
        width: "1%",
        headerStyle: {
          minWidth: "10px",
        },
        render: (rowData) => (
          <>
            <img
              src={rowData.news_pic}
              width="50px"
              style={{ marginTop: "-15px", marginBottom: "-15px" }}
            ></img>
          </>
        ),
      },

      {
        title: "",
        field: "news_type",
        width: "1%",
        headerStyle: {
          minWidth: "10px",
        },
      },
      {
        title: "",
        field: "news_title",
        width: "90%",
        headerStyle: {
          minWidth: "150px",
        },
      },
    ],
  });

  //  Get Date from DB -----------------------------------------------------------------------------------------

  // const [dbData, setDbData] = useState();
  // const [dbData_Length, setDbData_Length] = useState();
  // const getData = (_cafe, _hardware) => {
  //   const response1 = axios
  //     .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_eclipsemy__news_list__News`)
  //     .then((date) => {
  //       setDbData(date.data);
  //       setDbData_Length(date.data.length);
  //       // console.log("cafe-hw-pc", date.data);
  //     });
  // };

  const [bool_poster_01, set_bool_poster_01] = useState(false);
  const [bool_poster_02, set_bool_poster_02] = useState(false);
  const [bool_poster_03, set_bool_poster_03] = useState(false);
  const [bool_poster_04, set_bool_poster_04] = useState(false);
  const [bool_poster_05, set_bool_poster_05] = useState(false);

  const testUrl_Exists = (_fileName) => {
    axios
      .get(
        `${REACT_APP_ECLIPSE_API}/testURLExist?url=${REACT_APP_ECLIPSE_API}/uploads/EclipseMy_Event/logo/` +
          _fileName
      )
      .then((res) => {
        if (_fileName === "prize1.png" && res.data === true) {
          set_bool_poster_01(true);
          // console.log("01", res);
        }
        if (_fileName === "prize2.png" && res.data === true) {
          set_bool_poster_02(true);
          // console.log("02", res);
        }
        if (_fileName === "prize3.png" && res.data === true) {
          set_bool_poster_03(true);
          // console.log("03", res);
        }
        if (_fileName === "prize4.png" && res.data === true) {
          set_bool_poster_04(true);
          // console.log("04", res);
        }
        if (_fileName === "prize5.png" && res.data === true) {
          set_bool_poster_05(true);
          // console.log("05", res);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // ------------------------------------------------ carousel
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // ------------------------------------------------- useEffect
  useEffect(() => {
    // getData();
    testUrl_Exists("prize1.png");
    testUrl_Exists("prize2.png");
    testUrl_Exists("prize3.png");
    testUrl_Exists("prize4.png");
    testUrl_Exists("prize5.png");
  }, []);

  // ------------------------------------------------ main
  return (
    <>
      <div>
        <Carousel
          interval={5000}
          // fade
          slide
          touch
          pause="hover"
          activeIndex={index}
          onSelect={handleSelect}
          style={{ textAlign: "center" }}
        >
          {/* //-------------------------------------------------- 01 */}
          {bool_poster_01 === true ? (
            <Carousel.Item>
              <img
                height="100%"
                className="d-block w-100"
                src={`${REACT_APP_ECLIPSE_API}/uploads/EclipseMy_Event/logo/prize1.png`}
                alt="First slide"
              />
              <Carousel.Caption>
                <Button
                  onClick={() => {
                    alert("clicked :" + index);
                  }}
                >
                  {/* More info */}
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
          ) : (
            ""
          )}
          {/* //-------------------------------------------------- 02 */}
          {bool_poster_02 === true ? (
            <Carousel.Item>
              <img
                height="100%"
                // style={{ maxWidth: "50%" }}
                className="d-block w-100"
                src={`${REACT_APP_ECLIPSE_API}/uploads/EclipseMy_Event/logo/prize2.png`}
                alt="Second slide"
              />

              <Carousel.Caption>
                <Button
                  onClick={() => {
                    alert("clicked :" + index);
                  }}
                >
                  {/* More info */}
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
          ) : (
            ""
          )}
          {/* //-------------------------------------------------- 03 */}
          {bool_poster_03 === true ? (
            <Carousel.Item>
              <img
                height="100%"
                // style={{ maxWidth: "50%" }}
                className="d-block w-100"
                src={`${REACT_APP_ECLIPSE_API}/uploads/EclipseMy_Event/logo/prize3.png`}
                alt="Second slide"
              />

              <Carousel.Caption>
                <Button
                  onClick={() => {
                    alert("clicked :" + index);
                  }}
                >
                  {/* More info */}
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
          ) : (
            ""
          )}
          {/* //-------------------------------------------------- 04 */}
          {bool_poster_04 === true ? (
            <Carousel.Item>
              <img
                height="100%"
                // style={{ maxWidth: "50%" }}
                className="d-block w-100"
                src={`${REACT_APP_ECLIPSE_API}/uploads/EclipseMy_Event/logo/prize4.png`}
                alt="Second slide"
              />

              <Carousel.Caption>
                <Button
                  onClick={() => {
                    alert("clicked :" + index);
                  }}
                >
                  {/* More info */}
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
          ) : (
            ""
          )}
          {/* //-------------------------------------------------- 05 */}
          {bool_poster_05 === true ? (
            <Carousel.Item>
              <img
                height="100%"
                // style={{ maxWidth: "50%" }}
                className="d-block w-100"
                src={`${REACT_APP_ECLIPSE_API}/uploads/EclipseMy_Event/logo/prize5.png`}
                alt="Second slide"
              />

              <Carousel.Caption>
                <Button
                  onClick={() => {
                    alert("clicked :" + index);
                  }}
                >
                  {/* More info */}
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
          ) : (
            ""
          )}
        </Carousel>
      </div>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import Page_ChatRoom_Room from "./Page_ChatRoom_Room";

import moment from "moment";
import axios from "axios";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { pink } from "@mui/material/colors";

import classNames from "classnames";

import { FcGoogle as GoogleIcon } from "react-icons/fc";

// -------------------------------------------------  firebase - 1/3
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

const socket = io.connect("http://localhost:8001");
// const socket = io.connect("https://eclipserdp.dvrdns.org");

export default function Page_ChatRoom_Lobby() {
  // -------------------------------------------------  firebase - 2/3
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  const singInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        console.log("re", re);
      })
      .catch((err) => {
        console.log("re-err", err);
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        console.log("re", re);
      })
      .catch((err) => {
        console.log("re-err", err);
      });
  };

  // -------------------------------------------------  firebase - 3/3

  useEffect(() => {
    // console.log("#useEffect 1", firebase_userInfo?.displayName);
    if (firebase_userInfo?.uid) {
      // console.log("a1", firebase_userInfo?.uid);
      // console.log("a1", firebase_userInfo?.email);
      getFirebaseAPI_DB_users_List(firebase_userInfo?.uid);

      username.current =
        firebase_userInfo?.providerData?.find(
          (x) => x.providerId == "facebook.com"
        ) != undefined
          ? firebase_userInfo?.providerData?.find(
              (x) => x.providerId == "facebook.com"
            ).displayName
          : "No Name";
      room.current =
        "*q+qm2/{[SAD_5'eTuf]@kCb%y-<JBb.@T]nLvAWv2&/B`YH.)7K6$J2K?f=fnT~";
      joinRoom_Auto(username.current, room.current);

      console.log(
        "x",
        firebase_userInfo?.providerData?.find(
          (x) => x.providerId == "facebook.com"
        ) != undefined
          ? firebase_userInfo?.providerData?.find(
              (x) => x.providerId == "facebook.com"
            ).displayName
          : "No Name"
      );
    }
  }, [firebase_userInfo]);

  // ------------------------------------------------- DB

  // get user info by uid
  const [firebaseAPI_DB_users, setFirebaseAPI_DB_users] = useState([]);
  const getFirebaseAPI_DB_users_List = async (user_login_uid) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_user__byuid?user_login_uid=${user_login_uid}`
      )
      .then((res) => {
        setFirebaseAPI_DB_users(res.data[0]);
        console.log("user_login_uid", user_login_uid);
        console.log("getFirebaseAPI_DB_users_List", res.data[0]);
        if (res.data[0] != undefined) {
          // that is registered user
          setRegisteredUserStatus(true);
          // get the user data
          setDB_username(res.data[0].user_login_name);
          setSteamName(res.data[0].user_steamname);
          setRole(res.data[0].user_role);
          setUid(res.data[0].user_login_uid);
        } else {
          setRegisteredUserStatus(false);
        }
      });
  };

  const [registeredUserStatus, setRegisteredUserStatus] = useState(false);
  const [steamName, setSteamName] = useState("NA");
  const [role, setRole] = useState("NA");
  const [uid, setUid] = useState("NA");
  const [DB_username, setDB_username] = useState("NA");

  // -------------------------------------------------  Chat room
  // const [username, setUsername] = useState("");
  // const [room, setRoom] = useState("");
  const username = useRef("");
  const room = useRef("");

  const [showChat, setShowChat] = useState(false);

  const joinRoom = () => {
    if (username.current !== "" && room.current !== "") {
      socket.emit("join_room", room.current);
      setShowChat(true);
    }
  };
  const joinRoom_Auto = (_username, _room) => {
    if (_username !== "" && _room !== "") {
      socket.emit("join_room", _room);
      setShowChat(true);
    }
  };

  return (
    <div
      style={{
        textAlign: "center",
        // padding: "0px 50px 0px 50px ",
      }}
    >
      <Button
        style={{
          backgroundColor: "#ffffff",
          color: "#000000",
          width: "50%",
          textTransform: "none",
        }}
        onClick={() => {
          singInWithGoogle();
        }}
      >
        <GoogleIcon style={{ padding: "0px 10px 0px 0px", fontSize: "30px" }} />
        Login with Google
      </Button>
      <br />
      <br />
      <Button
        style={{
          backgroundColor: "#4064AC",
          color: "#ffffff",
          width: "50%",
          textTransform: "none",
        }}
        onClick={() => {
          signInWithFacebook();
        }}
      >
        <FacebookIcon
          style={{ padding: "0px 10px 0px 0px", fontSize: "30px" }}
        />{" "}
        Login with Facebook
      </Button>

      {firebase_userInfo ? (
        <div
          style={{
            backgroundColor: "#4f4f4f",
            padding: "20px 0px 0px 20px",
            borderRadius: "10px",
          }}
        >
          <div style={{ color: "#ffffff" }}>
            <img
              src={
                firebase_userInfo?.providerData?.find(
                  (x) => x.providerId == "facebook.com"
                ) != undefined
                  ? firebase_userInfo?.providerData?.find(
                      (x) => x.providerId == "facebook.com"
                    ).photoURL
                  : `https://avatars.dicebear.com/api/pixel-art/${firebase_userInfo?.uid}.svg`
              }
              style={{ borderRadius: "50%", height: "60px" }}
            />

            <div>
              {firebase_userInfo?.providerData?.find(
                (x) => x.providerId == "facebook.com"
              ) != undefined
                ? firebase_userInfo?.providerData?.find(
                    (x) => x.providerId == "facebook.com"
                  ).displayName
                : "No Name"}
            </div>
            <div>{firebase_userInfo?.email}</div>

            <div style={{ color: "gray" }}>{firebase_userInfo?.uid}</div>
            <div style={{ color: "gold" }}>
              {registeredUserStatus ? "Registered User" : "Not yet Register"}
            </div>
            <div style={{ color: "gold" }}>{steamName}</div>
          </div>
          <Button
            style={{
              color: "#ff0000",
              width: "20%",
              textTransform: "none",
            }}
            onClick={() => {
              signOut(authentication);
            }}
          >
            LOGOUT
          </Button>
        </div>
      ) : (
        <div></div>
      )}

      {!showChat ? (
        <div className="joinChatContainer">
          <h3>Join A Chat</h3>

          <input
            type="text"
            placeholder="your Name"
            onChange={(event) => {
              username.current = event.target.value;
            }}
          />
          <input
            type="text"
            placeholder="your Room ID"
            onChange={(event) => {
              room.current = event.target.value;
            }}
          />
          <button onClick={joinRoom}> Join A Room</button>
        </div>
      ) : (
        <Page_ChatRoom_Room
          socket={socket}
          username={DB_username}
          photoURL={
            firebase_userInfo?.providerData?.find(
              (x) => x.providerId == "facebook.com"
            ) != undefined
              ? firebase_userInfo?.providerData?.find(
                  (x) => x.providerId == "facebook.com"
                ).photoURL
              : `https://avatars.dicebear.com/api/pixel-art/${firebase_userInfo?.uid}.svg`
          }
          room={room.current}
          role={role}
          steamName={steamName}
          uid={uid}
        />
      )}
    </div>
  );
}

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

//ref fb login: https://www.youtube.com/watch?v=kEfe9u5F_L0&t=33s
//ref email login & logout: https://www.youtube.com/watch?v=9bXhf_TELP4

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyAMgl74kC2FRhj094v0ORNY5sSOmXbSNrc",
  authDomain: "eclipsemy---care-you-center.firebaseapp.com",
  projectId: "eclipsemy---care-you-center",
  storageBucket: "eclipsemy---care-you-center.appspot.com",
  messagingSenderId: "894157774952",
  appId: "1:894157774952:web:87196235cdefece4badbdd",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);

import react, { useState, useEffect } from "react";

// import { styled } from '@mui/material/styles';
import { Box, Paper, Grid, Typography, Button, Tab } from "@mui/material";

import Page_ScoreBoard from "./Page_ScoreBoard";
import Page_ChatRoom_Lobby from "./Page_ChatRoom_Lobby";
import Page_TimerCountDown from "./Page_TimerCountDown";
import Page_Event from "./Page_Event";
import Page_Redeem from "./Page_Redeem";
import Page_Register from "./Page_Register";

import COM_Carousel from "../Component/COM_Carousel";
import COM_Carousel2 from "../Component/COM_Carousel2";

import { TabContext, TabList, TabPanel } from "@mui/lab";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

export default function X() {
  //---------------------------------- tabs
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <br />
      Event Main Page
      <br />
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <div style={{ backgroundColor: "red" }}>
            A1
            <Grid item xs={12}>
              <Page_TimerCountDown />
            </Grid>
            <Grid item xs={12}>
              <div style={{ backgroundColor: "gold" }}>
                B1 : Nav
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        style={{ backgroundColor: "#00AEEF" }}
                      >
                        <Tab
                          label={
                            <>
                              <img
                                src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Sades2.png"
                                height="30px"
                              />
                            </>
                          }
                          value="1"
                        />
                        <Tab label="Watch" value="1" />
                        <Tab label="Event" value="2" />
                        <Tab label="Register" value="3" />
                        <Tab label="Redeem" value="4" />
                        <Tab
                          style={{
                            position: "absolute",
                            right: "0",
                          }}
                          label="LOGOUT"
                          value="5"
                          onClick={() => {
                            signOut(authentication);
                            setTimeout(() => {
                              window.location.href = "/";
                            }, 1000);
                          }}
                        />
                      </TabList>
                    </Box>
                    <TabPanel
                      value="1"
                      style={{
                        padding: 0,
                      }}
                    >
                      <Grid item xs={12}>
                        <div style={{ backgroundColor: "blue" }}>
                          B2 : Welcome
                          <Grid container spacing={1}>
                            <Grid item xs={3}>
                              <div style={{ backgroundColor: "lime" }}>
                                B3 : Prizes
                                <COM_Carousel />
                              </div>
                            </Grid>
                            <Grid item xs={9}>
                              <div
                                class="video-container"
                                style={{ backgroundColor: "lime" }}
                              >
                                B4 : Live video
                                <iframe
                                  // width={560 * 0.4} //560 56
                                  // height={315 * 0.4} //315  31

                                  src="https://www.youtube.com/embed/videoseries?list=PLSOdobpV2V0YNtckWKzL97NOhg72WO0I9&autoplay=1&mute=1"
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen=""
                                ></iframe>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        C1
                        <Grid container spacing={1}>
                          <Grid item xs={3}>
                            <div
                              class="video-container"
                              style={{ backgroundColor: "lime" }}
                            >
                              C2 : YT logo_Eclipse
                              <iframe
                                // width={560 * 0.4} //560 56
                                // height={315 * 0.4} //315  31

                                src="https://www.youtube.com/embed/videoseries?list=PLSOdobpV2V0YNtckWKzL97NOhg72WO0I9&autoplay=1&mute=1"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen=""
                              ></iframe>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div style={{ backgroundColor: "purple" }}>
                              C3 : Now
                              <COM_Carousel2 />
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div style={{ backgroundColor: "lime" }}>
                              C4 : Comming
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Page_Event />
                    </TabPanel>
                    <TabPanel value="3">
                      <Page_Register />
                    </TabPanel>
                    <TabPanel value="4">
                      <Page_Redeem />
                    </TabPanel>
                    <TabPanel value="5"></TabPanel>
                  </TabContext>
                </Box>
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ backgroundColor: "gold" }}>D </div>
          <Grid item xs={12}>
            <div style={{ backgroundColor: "lime" }}>D1 : Scoreboard </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={
                {
                  //  backgroundColor: "lime"
                }
              }
            >
              D2 : Chat
              <Page_ChatRoom_Lobby />{" "}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <div style={{ fontSize: "40px", color: "gold", textAlign: "center" }}>
        Like & Share
      </div>
      <br />
      <Grid
        container
        spacing={3}
        style={{ color: "gold", padding: "10px 10px 10px 10px " }}
      >
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Sades.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/sades.my"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Serverdna.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/serverdna"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Maxsun.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/MaxsunMY"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_TitanArmy.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/TitanArmyMy"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Madtech.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/madtech.my"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_InWin.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/inwin.my"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Eclipse.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/EclipseGroupMY"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Alitrade.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/AlitradeMY"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import react, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { pink } from "@mui/material/colors";

import classNames from "classnames";

import { FcGoogle as GoogleIcon } from "react-icons/fc";

import COM_UserProfile from "../Component/COM_UserProfile";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // -------------------------------------------------  Counter
  const [day, setDay] = useState();
  const [hour, setHour] = useState();
  const [min, setMin] = useState();
  const [sec, setSec] = useState();

  const [randomHSL, setRandomHSL] = useState();

  const DateTimeCounter = () => {
    var now = "04/12/2021 11:00:00";
    var then = "25/11/2021 00:00:00";

    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
      moment(moment(), "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);

    // console.log(
    //   d.days() + ":" + d.hours() + ":" + d.minutes() + ":" + d.seconds()
    // );
    // setDatetime(
    //   d.days() +
    //     "Day " +
    //     d.hours() +
    //     "Hours " +
    //     d.minutes() +
    //     "Minutes " +
    //     d.seconds() +
    //     "Secs"
    // );
    setDay(d.days());
    setHour(d.hours());
    setMin(d.minutes());
    setSec(d.seconds());
  };

  useEffect(() => {
    setTimeout(() => {
      DateTimeCounter();
      setRandomHSL(Math.floor(Math.random() * 300));
    }, 1000);
  }, [sec]);

  useEffect(() => {
    DateTimeCounter();
  }, []);

  // -------------------------------------- Global Variable

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={2} />
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: "#303030",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    align="center"
                    style={{
                      fontSize: "80px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {day}
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      fontSize: "40px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    DAY
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: "#303030",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    align="center"
                    style={{
                      fontSize: "80px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {hour}
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      fontSize: "40px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    HOUR
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: "#303030",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    align="center"
                    style={{
                      fontSize: "80px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {min}
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      fontSize: "40px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    MIN
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: "#303030",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    align="center"
                    style={{
                      fontSize: "80px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {sec}
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      fontSize: "40px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    SEC
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center", color: "gold" }}>
            Event Start at 4th December 2021, 11AM (GMT +8)
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

import React from "react";
import Page_PreRegister from "./Page_PreRegister";

export default function Page_Register() {
  return (
    <div style={{ backgroundColor: "black", borderRadius: "10px" }}>
      <Page_PreRegister />
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

// ------------------------------------------------- MUI Table 1/3

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

export default function Page_Home() {
  return (
    <>
      <div>
        <img
          src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/now1.png"
          width="100%"
        />
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import moment from "moment";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

export default function Page_ChatRoom_Room({
  socket,
  username,
  photoURL,
  room,
  role,
  steamName,
  uid,
}) {
  // ------------------------------------------------- Props

  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);

  const sendMessage = async () => {
    console.log("photoURL :", photoURL);
    if (currentMessage !== "") {
      const messageData = {
        room: room,
        author: username,
        photoURL: photoURL,
        role: role,
        steamName: steamName,
        message: currentMessage,
        time: moment().format("HH:mm:ss"),
        uid: uid,
      };

      await socket.emit("send_message", messageData);
      setMessageList((list) => [...list, messageData]);
      setCurrentMessage("");
    }
  };

  useEffect(() => {
    console.log("username", username);
  }, []);

  useEffect(() => {
    socket.on("received_message", (data) => {
      setMessageList((list) => [...list, data]);
    });
  }, [socket]);

  return (
    <div className="chat-window">
      <div className="chat-header">
        <p>Live Chat</p>
      </div>
      <div className="chat-body">
        <ScrollToBottom className="message-container">
          {messageList.map((messageContent) => {
            return (
              <>
                <div
                  className="message"
                  // id={username === messageContent.author ? "you" : "other"}
                  id={uid === messageContent.uid ? "you" : "other"}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        style={{
                          borderRadius: "30px",
                          height: "30px",
                        }}
                        src={messageContent.photoURL}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ color: "black" }}>
                        <b>{`${messageContent.author}`}</b>
                      </div>
                      <div
                        style={{
                          color: "gray",
                          fontSize: "10px",
                          marginTop: "-5px",
                        }}
                      >
                        <>{`${messageContent.steamName}`}</>
                      </div>
                      <div
                        style={{
                          color: "blue",
                          fontSize: "10px",
                          marginTop: "-5px",
                        }}
                      >
                        <>{`${messageContent.uid}`}</>
                      </div>

                      {messageContent.role === "admin" ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginTop: "-5px",
                          }}
                        >
                          <b>{`${messageContent.role}`}</b>
                        </div>
                      ) : (
                        <div
                          style={{
                            color: "blue",
                            fontSize: "10px",
                            marginTop: "-5px",
                          }}
                        >
                          <b>{`${messageContent.role}`}</b>
                        </div>
                      )}
                    </div>
                    <div className="message-content">
                      <div>{messageContent.message}</div>
                    </div>
                  </div>
                </div>

                <div
                  className="message"
                  // id={username === messageContent.author ? "you" : "other"}
                  id={uid === messageContent.uid ? "you" : "other"}
                >
                  <div className="message-meta">
                    <div
                      style={{
                        color: "gray",
                        fontSize: "9px",
                        marginTop: "-3px",
                      }}
                    >{`${messageContent.time}`}</div>
                  </div>
                </div>
              </>
            );
          })}
        </ScrollToBottom>
      </div>
      <div style={{ display: "flex" }}>
        <TextField
          fullWidth
          autoFocus
          type="text"
          value={currentMessage}
          placeholder="I want to say..."
          InputLabelProps={{
            shrink: true,
            style: {
              fontSize: 20,
              color: "#ffffff",
              // backgroundColor: "black",
            },
          }}
          inputProps={{
            shrink: true,
            style: {
              fontSize: 30,
              color: "#73ff00",
              // backgroundColor: "black",
            },
          }}
          onChange={(event) => {
            setCurrentMessage(event.target.value);
          }}
          onKeyPress={(event) => {
            event.key === "Enter" && sendMessage();
          }}
        />
        <Button
          onClick={sendMessage}
          style={{ color: "#73ff00", fontSize: "30px" }}
        >
          &#9658;
        </Button>
      </div>
    </div>
  );
}

import react from "react";
import axios from "axios";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

export default function X() {
  const getData = () => {
    console.log("sss");

    axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__ticket_record__recordAll`
      )
      .then(async (data) => {
        console.log("data", data);
      });
    return;
    <>data</>;
  };

  return <>Video :{getData()}</>;
}

import react, { useEffect, useState } from "react";
import { Box, Paper, Grid, Button, TextField } from "@mui/material";

export default function X() {
  return (
    <>
      <div>
        <img
          src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/eventDesc1.jpg"
          width="100%"
        />
        <img
          src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/eventDesc2.jpg"
          width="100%"
        />
        <img
          src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/eventDesc3.jpg"
          width="100%"
        />
      </div>
    </>
  );
}

import react, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { pink } from "@mui/material/colors";

import classNames from "classnames";

import { FcGoogle as GoogleIcon } from "react-icons/fc";

import COM_UserProfile from "../Component/COM_UserProfile";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // ------------------------------------------------- DB

  // get user info by uid
  const [firebaseAPI_DB_users, setFirebaseAPI_DB_users] = useState([]);
  const getFirebaseAPI_DB_users_List = async (user_login_uid) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_user__byuid?user_login_uid=${user_login_uid}`
      )
      .then((res) => {
        setFirebaseAPI_DB_users(res.data[0]);
        // console.log("getFirebaseAPI_DB_users_List", res.data[0]);
        if (res.data[0] != undefined) {
          // that is registered user
          setRegisteredUserStatus(true);
          // get the user data
          setDeliveryAdd(res.data[0].user_deliveryadd);
          setPostCode(res.data[0].user_postcode);
          setCity(res.data[0].user_city);
          setContactNo(res.data[0].user_contactno);
          setSteamName(res.data[0].user_steamname);
          setCb_User_event1_checkpoint(
            res.data[0].user_event1_checkpoint === "true"
          );
        } else {
          setRegisteredUserStatus(false);
        }
      });
  };

  const registerEvent1 = () => {
    console.log("#1. ", firebaseAPI_DB_users);
    if (firebaseAPI_DB_users == undefined) {
      setTimeout(() => {
        console.log("#new user");
        addFirebaseAPI_DB_users_List(
          firebase_userInfo.uid,
          // firebase_userInfo?.providerData?.find((x) => x.email != null) !=
          //   undefined
          //   ? firebase_userInfo?.providerData?.find((x) => x.email != null)
          //       .email
          //   : "no email",
          firebase_userInfo?.providerData?.find(
            (x) => x.providerId == "facebook.com"
          ) != undefined
            ? firebase_userInfo?.providerData?.find(
                (x) => x.providerId == "facebook.com"
              ).email
            : "No Email",
          firebase_userInfo?.providerData?.find(
            (x) => x.providerId == "facebook.com"
          ) != undefined
            ? firebase_userInfo?.providerData?.find(
                (x) => x.providerId == "facebook.com"
              ).displayName
            : "No Name",
          firebase_userInfo?.providerData?.find(
            (x) => x.providerId == "facebook.com"
          ) != undefined
            ? firebase_userInfo?.providerData?.find(
                (x) => x.providerId == "facebook.com"
              ).photoURL
            : "No Pic",
          "participant",
          deliveryAdd,
          postCode,
          city,
          contactNo,
          steamName,
          user_event1_checkpoint
        );
        getFirebaseAPI_DB_users_List(firebase_userInfo?.uid);
      }, 2000);
    } else {
      alert("you already registered!");
      getFirebaseAPI_DB_users_List(firebase_userInfo?.uid);
    }
  };

  const [registeredUserStatus, setRegisteredUserStatus] = useState(false);

  // add new user
  const addFirebaseAPI_DB_users_List = async (
    user_login_uid,
    user_login_email,
    user_login_name,
    user_profile_pic_url,
    user_role,
    deliveryAdd,
    postCode,
    city,
    contactNo,
    steamName,
    user_event1_checkpoint
  ) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/add_eclipse_event__event_user?user_login_uid=${user_login_uid}&user_login_email=${user_login_email}&user_login_name=${user_login_name}&user_profile_pic_url=${user_profile_pic_url}&user_role=${user_role}&user_deliveryadd=${deliveryAdd}&user_postcode=${postCode}&user_city=${city}&user_contactno=${contactNo}&user_steamname=${steamName}&user_event1_checkpoint=${user_event1_checkpoint}`
      )
      .then((res) => {
        console.log("res1", res);
        console.log("addFirebaseAPI_DB_users_List", res.data.affectedRows);

        if (res.data.affectedRows > 0) {
          alert("Register Completed!");
          setRegisteredUserStatus(true);
        } else {
          if (res.data.code === "ER_DUP_ENTRY") {
            alert(
              "Steam Name - Already been Registered, Please try another account!"
            );
          }

          alert("Register Failed!");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // update user info
  const setFirebaseAPI_DB_users_List = async (
    user_login_uid,

    deliveryAdd,
    postCode,
    city,
    contactNo,
    steamName,
    user_event1_checkpoint
  ) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_event__event_user?user_login_uid=${user_login_uid}&user_deliveryadd=${deliveryAdd}&user_postcode=${postCode}&user_city=${city}&user_contactno=${contactNo}&user_steamname=${steamName}&user_event1_checkpoint=${user_event1_checkpoint}`
      )
      .then((res) => {
        // console.log("res2", res);
        // console.log("setFirebaseAPI_DB_users_List", res.data.affectedRows);

        if (res.data.affectedRows > 0) {
          alert("Update Completed!");
          setRegisteredUserStatus(true);
        } else {
          if (res.data.code === "ER_DUP_ENTRY") {
            alert(
              "Steam Name - Already been Registered, Please try another account!"
            );
          }

          alert("Register Failed!");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  const singInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        console.log("re", re);
      })
      .catch((err) => {
        console.log("re-err", err);
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        console.log("re", re);
      })
      .catch((err) => {
        console.log("re-err", err);
      });
  };

  // -------------------------------------------------  Counter
  const [day, setDay] = useState();
  const [hour, setHour] = useState();
  const [min, setMin] = useState();
  const [sec, setSec] = useState();

  const [randomHSL, setRandomHSL] = useState();

  const DateTimeCounter = () => {
    var now = "04/12/2021 11:00:00";
    var then = "25/11/2021 00:00:00";

    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
      moment(moment(), "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);

    // console.log(
    //   d.days() + ":" + d.hours() + ":" + d.minutes() + ":" + d.seconds()
    // );
    // setDatetime(
    //   d.days() +
    //     "Day " +
    //     d.hours() +
    //     "Hours " +
    //     d.minutes() +
    //     "Minutes " +
    //     d.seconds() +
    //     "Secs"
    // );
    setDay(d.days());
    setHour(d.hours());
    setMin(d.minutes());
    setSec(d.seconds());
  };

  useEffect(() => {
    setTimeout(() => {
      DateTimeCounter();
      setRandomHSL(Math.floor(Math.random() * 300));
    }, 1000);
  }, [sec]);

  useEffect(() => {
    DateTimeCounter();
  }, []);

  // -------------------------------------------------  firebase - 3/3

  useEffect(() => {
    // console.log("#useEffect 1", firebase_userInfo?.displayName);
    if (firebase_userInfo?.uid) {
      // console.log("a1", firebase_userInfo?.uid);
      // console.log("a1", firebase_userInfo?.email);
      getFirebaseAPI_DB_users_List(firebase_userInfo?.uid);

      // window.location.href = "#/main";
      // nevigate to main page
    }
  }, [firebase_userInfo]);

  // -------------------------------------- Global Variable

  const [deliveryAdd, setDeliveryAdd] = useState();
  const [postCode, setPostCode] = useState();
  const [city, setCity] = useState();
  const [contactNo, setContactNo] = useState();
  const [steamName, setSteamName] = useState(
    "dummyAcc-" + Math.random() * 100000000000000000
  );

  const [user_event1_checkpoint, setCb_User_event1_checkpoint] =
    useState(false);
  const hC_cb_User_event1_checkpoint = (event) => {
    setCb_User_event1_checkpoint(event.target.checked);
    setSteamName("");
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div style={{ backgroundColor: "#303030", textAlign: "center" }}>
              <img
                src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Sades.png"
                height="50px"
              />
              <img
                src="https://www.serverdna.asia/wp-content/uploads/2018/07/serverdna.png"
                height="50px"
              />
            </div>
            <div style={{ backgroundColor: "#303030", textAlign: "center" }}>
              <img
                src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Maxsun.png"
                height="30px"
              />{" "}
              <img
                src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_TitanArmy.png"
                height="30px"
              />{" "}
              <img
                src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Madtech.png"
                height="30px"
              />{" "}
              <img
                src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_InWin.png"
                height="30px"
              />{" "}
              <img
                src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Eclipse.png"
                height="30px"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <br />
            <br />
            <br />
            <Typography
              align="center"
              style={{
                fontSize: "40px",
                textAlign: "center",
                color: "#ffc400",
              }}
            >
              Sades Ultimate Game Challenge
            </Typography>

            <br />
            <br />
            <br />
            <Grid container spacing={1}>
              <Grid item xs={2} />
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: "#303030",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    align="center"
                    style={{
                      fontSize: "80px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {day}
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      fontSize: "40px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    DAY
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: "#303030",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    align="center"
                    style={{
                      fontSize: "80px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {hour}
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      fontSize: "40px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    HOUR
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: "#303030",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    align="center"
                    style={{
                      fontSize: "80px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {min}
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      fontSize: "40px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    MIN
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: "#303030",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    align="center"
                    style={{
                      fontSize: "80px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {sec}
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      fontSize: "40px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    SEC
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center", color: "gold" }}>
            Event Start at 4th December 2021, 11AM (GMT +8)
          </Grid>

          <Grid item xs={12}>
            <br />
            <br />
            <br />
            <div
              style={{
                // color: `#${Math.floor(Math.random() * 10000000).toString(16)}`, //#ffc400
                // color: `linear-gradient(to right, #30CFD0 0%, #330867 100%)`,
                backgroundImage: `linear-gradient(60deg, hsl(${
                  randomHSL - 60
                }, 100%, 50%), hsl(${randomHSL + 60}, 100%, 100%))`,
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
                textAlign: "center",
                fontSize: `100px`,
                fontFamily: "Comforter",
                // textShadow: `0px 0px 10px hsl(${randomHSL + 20}, 50%, 50%)`,
              }}
            ></div>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <div
              style={{
                backgroundColor: "#333232",
                textAlign: "center",
                padding: "20px 20px 20px 20px",
                borderRadius: "20px",
              }}
            >
              <Typography
                align="center"
                style={{
                  // backgroundImage: `linear-gradient(60deg, hsl(${
                  //   randomHSL - 160
                  // }, 100%, 50%), hsl(${randomHSL + 160}, 100%, 50%))`,
                  backgroundColor: "black",
                  fontSize: "40px",
                  color: `hsl(${randomHSL + 60}, 100%, 50%)`,
                  fontSize: "50px",
                  textAlign: "center",
                  color: `hsl(${randomHSL + 60}, 100%, 80%)`,
                  borderRadius: "10px",
                }}
              >
                <div> 100 units of</div>
                <div> Sades Goodie Bags</div>
                <div> GIVING OUT</div>
              </Typography>
              <br />
              <Typography
                align="center"
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                First come first served , T&C apply
              </Typography>
              <Typography
                align="center"
                style={{
                  fontSize: "50px",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Pre-Register NOW
              </Typography>
              {/* // ------------------------------------------ testing  */}
              {/* {firebase_userInfo?.providerData?.map((x) => (
                <>
                  <div>providerId : {x.providerId}</div>
                  <div>email: {x.email}</div>
                  <img src={x.photoURL}></img>
                  <hr />
                </>
              ))}
              */}
              <br />
              {firebase_userInfo ? (
                <div
                  style={{
                    backgroundColor: "#4f4f4f",
                    padding: "20px 0px 0px 20px",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ color: "#ffffff" }}>
                    <img
                      src={
                        firebase_userInfo?.providerData?.find(
                          (x) => x.providerId == "facebook.com"
                        ) != undefined
                          ? firebase_userInfo?.providerData?.find(
                              (x) => x.providerId == "facebook.com"
                            ).photoURL
                          : "No Pic"
                      }
                      style={{ borderRadius: "50%" }}
                    />

                    <div>
                      {firebase_userInfo?.providerData?.find(
                        (x) => x.providerId == "facebook.com"
                      ) != undefined
                        ? firebase_userInfo?.providerData?.find(
                            (x) => x.providerId == "facebook.com"
                          ).displayName
                        : "No Name"}
                    </div>
                    {/* <div>{firebase_userInfo?.email}</div> */}
                    <div>
                      {firebase_userInfo?.providerData?.find(
                        (x) => x.providerId == "facebook.com"
                      ) != undefined
                        ? firebase_userInfo?.providerData?.find(
                            (x) => x.providerId == "facebook.com"
                          ).email
                        : "No Email"}
                    </div>

                    <div style={{ color: "gray" }}>
                      {firebase_userInfo?.uid}
                    </div>
                  </div>
                  <Button
                    style={{
                      color: "#ff0000",
                      width: "20%",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      signOut(authentication);
                    }}
                  >
                    LOGOUT
                  </Button>
                  <br />
                  <br />
                  <br />

                  <TextField
                    style={{
                      width: "80%",
                      padding: "5px 5px 5px 5px ",
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontSize: 20,
                        color: "#ffffff",
                        // backgroundColor: "black",
                      },
                    }}
                    inputProps={{
                      style: {
                        fontSize: 30,
                        color: "#73ff00",
                      },
                    }}
                    label="green"
                    borderColor="green"
                    id="outlined-basic"
                    label="Delivery Address"
                    variant="outlined"
                    placeholder="Your Delivery Address"
                    helperText={
                      <div>
                        <div
                          style={{
                            color: "red",
                            float: "left",
                            fontSize: "20px",
                            padding: "0px 20px 20px 0px",
                            marginTop: "-7px",
                          }}
                        >
                          *
                        </div>
                        <div style={{ color: "gray" }}>
                          ie. No123,Jln 456, Tmn789. Tanjung Rambutan. Teluk
                          Intan
                        </div>
                      </div>
                    }
                    value={deliveryAdd}
                    onChange={(e) => {
                      setDeliveryAdd(
                        e.target.value.replace(
                          /[^0-9 a-z A-Z .,/#@&^%'*()+=-]/g,
                          ""
                        )
                      );
                    }}
                  />
                  <TextField
                    style={{ width: "39%", padding: "5px 5px 5px 5px " }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontSize: 20,
                        color: "#ffffff",
                        // backgroundColor: "black",
                      },
                    }}
                    inputProps={{
                      shrink: true,
                      style: {
                        fontSize: 30,
                        color: "#73ff00",
                        // backgroundColor: "black",
                      },
                    }}
                    id="outlined-basic"
                    label="Postcode"
                    variant="outlined"
                    placeholder="Postcode"
                    helperText={
                      <div>
                        <div
                          style={{
                            color: "red",
                            float: "left",
                            fontSize: "20px",
                            padding: "0px 20px 20px 0px",
                            marginTop: "-7px",
                          }}
                        >
                          *
                        </div>
                        <div style={{ color: "gray" }}>
                          ie. 53300, 25300, 37800
                        </div>
                      </div>
                    }
                    value={postCode}
                    onChange={(e) => {
                      setPostCode(e.target.value.replace(/[^0-9]/g, ""));
                    }}
                  />
                  <TextField
                    style={{ width: "39%", padding: "5px 5px 5px 5px " }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontSize: 20,
                        color: "#ffffff",
                        // backgroundColor: "black",
                      },
                    }}
                    inputProps={{
                      shrink: true,
                      style: {
                        fontSize: 30,
                        color: "#73ff00",
                        // backgroundColor: "black",
                      },
                    }}
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    placeholder="City"
                    helperText={
                      <div>
                        <div
                          style={{
                            color: "red",
                            float: "left",
                            fontSize: "20px",
                            padding: "0px 20px 20px 0px",
                            marginTop: "-7px",
                          }}
                        >
                          *
                        </div>
                        <div style={{ color: "gray" }}>
                          ie. KL, Perak, PP, Kedah
                        </div>
                      </div>
                    }
                    value={city}
                    onChange={(e) => {
                      setCity(
                        e.target.value.replace(
                          /[^0-9 a-z A-Z .,/#@&^%'*()+=-]/g,
                          ""
                        )
                      );
                    }}
                  />

                  <TextField
                    style={{ width: "80%", padding: "5px 5px 5px 5px " }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontSize: 20,
                        color: "#ffffff",
                        // backgroundColor: "black",
                      },
                    }}
                    inputProps={{
                      shrink: true,
                      style: {
                        fontSize: 30,
                        color: "#73ff00",
                        // backgroundColor: "black",
                      },
                    }}
                    id="outlined-basic"
                    label="Contact No"
                    variant="outlined"
                    placeholder="Contact No"
                    helperText={
                      <>
                        <div style={{ color: "white" }}>
                          * For Event Prizing Purpose only
                        </div>

                        <div style={{ color: "gray" }}>ie. 012-23456789</div>
                      </>
                    }
                    value={contactNo}
                    onChange={(e) => {
                      setContactNo(e.target.value.replace(/[^0-9]/g, ""));
                    }}
                  />

                  <br />
                  <br />
                  <br />
                  {!user_event1_checkpoint ? (
                    <div
                      style={{
                        width: "80%",
                        textAlign: "center",
                        padding: "0px 0px 0px 60px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={user_event1_checkpoint}
                            onChange={hC_cb_User_event1_checkpoint}
                            sx={{
                              color: "#ffff",
                              "&.Mui-checked": {
                                color: "#73ff00",
                              },
                              "& .MuiSvgIcon-root": { fontSize: 50 },
                            }}
                          />
                        }
                        label={
                          <div
                            style={{
                              color:
                                user_event1_checkpoint === true
                                  ? "#73ff00"
                                  : "gold",
                              textAlign: "center",
                            }}
                          >
                            I would like to join Sades Ultimate Game Challenge (
                            Crab Game )
                          </div>
                        }
                      />
                    </div>
                  ) : (
                    <div style={{ color: "#73ff00" }}>
                      Crab Game Stem Account Info !
                    </div>
                  )}
                  <br />
                  {user_event1_checkpoint == true ? (
                    <TextField
                      style={{ width: "80%", padding: "5px 5px 5px 5px " }}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          fontSize: 20,
                          color: "#ffffff",
                          // backgroundColor: "black",
                        },
                      }}
                      inputProps={{
                        shrink: true,
                        style: {
                          fontSize: 30,
                          color: "#73ff00",
                          // backgroundColor: "black",
                        },
                      }}
                      id="outlined-basic"
                      label="Steam Name"
                      variant="outlined"
                      placeholder="Steam Name (Optional)"
                      helperText={
                        <>
                          <div style={{ color: "white" }}>
                            * “ Crab Game ” will use your steam profile name as
                            In Game Name
                          </div>
                          <div style={{ color: "white" }}>
                            * Steam profile name cannot be change once
                            registered
                          </div>
                          <div style={{ color: "gray" }}>ie. Crab Game</div>
                        </>
                      }
                      value={steamName}
                      onChange={(e) => {
                        setSteamName(
                          e.target.value.replace(
                            /[^0-9 a-z A-Z .,/#@&^%*()+=-]/g,
                            ""
                          )
                        );
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {/* <br />
                  {"------ for dev only ------"}
                  <br />
                  {"deliveryAdd : " + deliveryAdd}
                  <br />
                  {"postCode : " + postCode}
                  <br />
                  {"city : " + city}
                  <br />
                  {"contactNo : " + contactNo}
                  <br />
                  {"steamName : " + steamName}
                  <br /> */}

                  <br />
                  <br />
                  {registeredUserStatus === true ? (
                    <Button
                      style={{
                        backgroundColor: "gold",
                        color: "black",
                        width: "50%",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setFirebaseAPI_DB_users_List(
                          firebase_userInfo?.uid,

                          deliveryAdd,
                          postCode,
                          city,
                          contactNo,
                          steamName,
                          user_event1_checkpoint
                        );
                      }}
                    >
                      UPDATE INFO
                    </Button>
                  ) : (
                    ""
                  )}

                  <br />
                  <br />

                  <Button
                    disabled={registeredUserStatus}
                    style={{
                      backgroundColor:
                        registeredUserStatus === false ? "#73ff00" : "#000000",
                      color:
                        registeredUserStatus === false ? "#5c5c5c" : "#73ff00",
                      width: "50%",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      if (
                        deliveryAdd == undefined ||
                        postCode == undefined ||
                        city == undefined ||
                        contactNo == undefined ||
                        deliveryAdd == "" ||
                        postCode == "" ||
                        city == "" ||
                        contactNo == ""
                      ) {
                        alert("Please fill in all info");
                      } else if (window.confirm("All Info Valid ?")) {
                        registerEvent1();
                      }
                    }}
                  >
                    <div>
                      {registeredUserStatus === false ? (
                        <>
                          <div>Register Earlybird FREE GOODIES BEG</div>

                          <div style={{ fontSize: "30px" }}>Click Me NOW!</div>
                        </>
                      ) : (
                        <>
                          <div> Earlybird FREE GOODIES BEG</div>
                          <div style={{ color: "#ffffff" }}>Pre-Register :</div>
                          <div style={{ fontSize: "30px" }}>SUCCESSFUL</div>
                        </>
                      )}
                    </div>
                  </Button>

                  <br />
                  <br />
                </div>
              ) : (
                <div></div>
              )}

              <br />
              <br />
              {firebase_userInfo?.uid == null ? (
                <>
                  <Button
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#000000",
                      width: "50%",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      singInWithGoogle();
                    }}
                  >
                    <GoogleIcon
                      style={{ padding: "0px 10px 0px 0px", fontSize: "30px" }}
                    />
                    Login with Google
                  </Button>

                  <br />
                  <br />
                  <Button
                    style={{
                      backgroundColor: "#4064AC",
                      color: "#ffffff",
                      width: "50%",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      signInWithFacebook();
                    }}
                  >
                    <FacebookIcon
                      style={{ padding: "0px 10px 0px 0px", fontSize: "30px" }}
                    />{" "}
                    Login with Facebook
                  </Button>
                </>
              ) : (
                ""
              )}

              <br />
              <br />
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "left",
                  color: "#b0b0b0",
                }}
              >
                <img
                  src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/prize1.jpg"
                  width="100%"
                  style={{ borderRadius: "30px" }}
                />
                <h3>Content of Goodie Bag :</h3>

                <h3>VIP set x 10 ( 1st to 10th eligible participants )</h3>
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: "20px",
                  }}
                >
                  <li>
                    Sades 10th Year Anniversary Mouse Pad / Maxsun Mousepad (
                    Random )
                  </li>
                  <li>Sades 10th Year Anniversary Badge </li>
                  <li>
                    Sades 10th Year Anniversary Notebook / Titan Army Notebook (
                    Random )
                  </li>
                  <li>Sades 10th Year Anniversary Pen</li>
                  <li>Sades 10th Year Anniversary Key Chains</li>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: "20px",
                    color: "gold",
                  }}
                >
                  <li>Sades special edition Cap</li>
                  <li>Maxsun Icraft Enchantment Heart Princess Pendrive</li>
                  <li>Maxsun Icraft Enchantment Heart Princess Magnet</li>
                  <li>
                    Maxsun Icraft Jacket / Pillow / Large MousePad / Sades
                    Global Limited Edition Doll / Sades Logo Projector Light  (
                    Random )
                  </li>
                </div>
                <br />
                <h3>
                  Regular set x 90 ( 11th to 100th eligible participants )
                </h3>
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: "20px",
                  }}
                >
                  <li>
                    Sades 10th Year Anniversary Mouse Pad / Maxsun Mousepad (
                    Random )
                  </li>
                  <li>Sades 10th Year Anniversary Badge </li>
                  <li>
                    Sades 10th Year Anniversary Notebook / Titan Army Notebook (
                    Random )
                  </li>
                  <li>Sades 10th Year Anniversary Pen</li>
                  <li>Sades 10th Year Anniversary Key Chains</li>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={12}>
            <br />
            <br />
            <br />
            <div
              style={{
                // color: `#${Math.floor(Math.random() * 10000000).toString(16)}`, //#ffc400
                // color: `linear-gradient(to right, #30CFD0 0%, #330867 100%)`,
                backgroundImage: `linear-gradient(60deg, hsl(${
                  randomHSL - 60
                }, 100%, 50%), hsl(${randomHSL + 60}, 100%, 100%))`,
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
                textAlign: "center",
                fontSize: `100px`,
                fontFamily: "Comforter",
                // textShadow: `0px 0px 10px hsl(${randomHSL + 20}, 50%, 50%)`,
              }}
            >
              Stay Tuned
            </div>
            {/* <Button
              style={{ backgroundColor: "green" }}
              onClick={() => {
                setT1("5555");
                alert("sss");
              }}
            >
              Server DNA
            </Button> */}
          </Grid>

          <Grid item xs={12}>
            <br />

            <br />
            <br />

            <div
              style={{
                backgroundImage: `linear-gradient(60deg, hsl(${
                  randomHSL - 60
                }, 20%, 20%), hsl(${randomHSL + 60}, 10%, 10%))`,
                textAlign: "center",
                padding: "5px 5px 5px 5px",
              }}
            >
              <br />
              <br />

              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/gWP-hd-rLHw?autoplay=1&mute=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                allow="autoplay; encrypted-media"
              ></iframe>

              <br />
              <br />

              <br />
              <br />
              <div
                style={{
                  // color: `#${Math.floor(Math.random() * 10000000).toString(16)}`, //#ffc400
                  // color: `linear-gradient(to right, #30CFD0 0%, #330867 100%)`,
                  backgroundImage: `linear-gradient(60deg, hsl(${
                    randomHSL - 60
                  }, 100%, 50%), hsl(${randomHSL + 60}, 100%, 100%))`,
                  "-webkit-background-clip": "text",
                  "-webkit-text-fill-color": "transparent",
                  textAlign: "center",
                  fontSize: `40px`,
                }}
              >
                LIKE & SHARE
              </div>
              <br />
              <Grid container spacing={1}>
                {/* //------------------------------- sades */}
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <img
                    src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Sades.png"
                    height="50px"
                  />
                </Grid>
                <Grid item xs={4}>
                  <div style={{ padding: "10px 0px 0px 50px" }}>
                    <div
                      class="fb-like"
                      data-href="https://www.facebook.com/sades.my"
                      data-width="100"
                      data-layout="standard"
                      data-action="like"
                      data-size="large"
                      data-colorscheme="dark"
                    ></div>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
                {/* //------------------------------- Server DNA */}
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <img
                    src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Serverdna.png"
                    height="50px"
                  />
                </Grid>
                <Grid item xs={4}>
                  <div style={{ padding: "10px 0px 0px 50px" }}>
                    <div
                      class="fb-like"
                      data-href="https://www.facebook.com/serverdna"
                      data-width="100"
                      data-layout="standard"
                      data-action="like"
                      data-size="large"
                      data-colorscheme="dark"
                    ></div>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
                {/* //------------------------------- Maxsun */}
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <img
                    src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Maxsun.png"
                    height="50px"
                  />
                </Grid>
                <Grid item xs={4}>
                  <div style={{ padding: "10px 0px 0px 50px" }}>
                    <div
                      class="fb-like"
                      data-href="https://www.facebook.com/MaxsunMY"
                      data-width="100"
                      data-layout="standard"
                      data-action="like"
                      data-size="large"
                      data-colorscheme="dark"
                    ></div>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
                {/* //------------------------------- TitanArmyMy */}
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <img
                    src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_TitanArmy.png"
                    height="50px"
                  />
                </Grid>
                <Grid item xs={4}>
                  <div style={{ padding: "10px 0px 0px 50px" }}>
                    <div
                      class="fb-like"
                      data-href="https://www.facebook.com/TitanArmyMy"
                      data-width="100"
                      data-layout="standard"
                      data-action="like"
                      data-size="large"
                      data-colorscheme="dark"
                    ></div>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
                {/* //------------------------------- madtech */}
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <img
                    src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Madtech.png"
                    height="50px"
                  />
                </Grid>
                <Grid item xs={4}>
                  <div style={{ padding: "10px 0px 0px 50px" }}>
                    <div
                      class="fb-like"
                      data-href="https://www.facebook.com/madtech.my"
                      data-width="100"
                      data-layout="standard"
                      data-action="like"
                      data-size="large"
                      data-colorscheme="dark"
                    ></div>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
                {/* //------------------------------- inwin.my */}
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <img
                    src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_InWin.png"
                    height="50px"
                  />
                </Grid>
                <Grid item xs={4}>
                  <div style={{ padding: "10px 0px 0px 50px" }}>
                    <div
                      class="fb-like"
                      data-href="https://www.facebook.com/inwin.my"
                      data-width="100"
                      data-layout="standard"
                      data-action="like"
                      data-size="large"
                      data-colorscheme="dark"
                    ></div>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
                {/* //------------------------------- EclipseGroupMY */}
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <img
                    src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Eclipse.png"
                    height="50px"
                  />
                </Grid>
                <Grid item xs={4}>
                  <div style={{ padding: "10px 0px 0px 50px" }}>
                    <div
                      class="fb-like"
                      data-href="https://www.facebook.com/EclipseGroupMY"
                      data-width="100"
                      data-layout="standard"
                      data-action="like"
                      data-size="large"
                      data-colorscheme="dark"
                    ></div>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
                {/* //------------------------------- AlitradeMY */}
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <img
                    src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Alitrade.png"
                    height="50px"
                  />
                </Grid>
                <Grid item xs={4}>
                  <div style={{ padding: "10px 0px 0px 50px" }}>
                    <div
                      class="fb-like"
                      data-href="https://www.facebook.com/AlitradeMY"
                      data-width="100"
                      data-layout="standard"
                      data-action="like"
                      data-size="large"
                      data-colorscheme="dark"
                    ></div>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
